import { Component } from '@angular/core';
import {FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Input} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute } from '@angular/router';
import {EmailService} from "../services/email.service";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-virman-isplata',
    templateUrl: './virman-isplata.component.html'
})
export class VirmanIsplataComponent {
    @Input() DonorName: string;
    @Input() DonorAddress: string;
    @Input() DonorBankAccNr: string;
    @Input() DonorBankName: string;
    @Input() DonorID: string;
    @Input() DonorBankAccountName: string;

    @Input() BeneficiaryID: string;
    @Input() BeneficiaryName: string;
    @Input() BeneficiaryAddress: string;
    @Input() BeneficiaryBankName: string;
    @Input() BeneficiaryBankAccNr: string;
    @Input() BeneficiaryBankAccountName: string;

    @Input() DirectProjectId: string;
    @Input() ProjectName: string;
    @Input() Amount: string;
    @Input() TargetType: string;
    @Input() PurposeText: string;


    public vimanForm: FormGroup;
    donationPurpose: string;


    constructor(private emailService: EmailService,
                private translate: TranslateService,
                private router: Router, private route: ActivatedRoute, private location: Location) {
        this.route.paramMap.subscribe(params => {
            this.vimanForm = new FormGroup({
                DonorName: new UntypedFormControl(null, Validators.required),
                DonorAddress: new UntypedFormControl(null, Validators.required),
                DonorBankAccNr: new UntypedFormControl(null, Validators.required),
                DonorBankName: new UntypedFormControl(null, Validators.required),
                DonorID: new UntypedFormControl(null, Validators.required),
                DonorBankAccountName: new UntypedFormControl(null, Validators.required),
                BeneficiaryID: new UntypedFormControl(null, Validators.required),

                BeneficiaryName: new UntypedFormControl(null, Validators.required),
                BeneficiaryAddress: new UntypedFormControl(null, Validators.required),
                BeneficiaryBankName: new UntypedFormControl(null, Validators.required),
                BeneficiaryBankAccNr: new UntypedFormControl(null, Validators.required),
                BeneficiaryBankAccountName: new UntypedFormControl(null, Validators.required),

                fixedReceiverName:new UntypedFormControl('КОНЕКТ Скопје - ОПОП'),
                fixedReceiverAddress:new UntypedFormControl('Ристо Шишков бр.12-1/4'),
                fixedReceiverBankName:new UntypedFormControl('Комерцијална банка АД Скопје'),
                fixedReceiverAccNr:new UntypedFormControl('300000004963235'),

                DirectProjectId: new UntypedFormControl(null, Validators.required),
                Amount: new UntypedFormControl(null, Validators.required),
                TargetType: new UntypedFormControl(null, Validators.required),
                ProjectName: new UntypedFormControl(null, Validators.required),
                PurposeText: new UntypedFormControl(null, Validators.required),
                PurposeNumber:new UntypedFormControl(null, Validators.required)
            });
            const formParams = {
                DonorName:              params.get('DonorName'),
                DonorAddress:           params.get('DonorAddress'),
                DonorBankAccNr:         params.get('DonorBankAccNr'),
                DonorBankName:          params.get('DonorBankName'),
                DonorID:                params.get('DonorID'),
                DonorBankAccountName:   params.get('DonorBankAccountName'),
                BeneficiaryID:          params.get('BeneficiaryID'),
                BeneficiaryAddress:     params.get('BeneficiaryAddress'),
                BeneficiaryBankName:    params.get('BeneficiaryBankName'),
                BeneficiaryBankAccNr:   params.get('BeneficiaryBankAccNr'),

                BeneficiaryBankAccountName: params.get('BeneficiaryBankAccountName'),
                DirectProjectId:        params.get('DirectProjectId'),
                ProjectName:            params.get('ProjectName'),
                Amount:                 params.get('Amount'),
                TargetType:             params.get('TargetType'),
                PurposeText:            params.get('PurposeText'),
                PurposeNumber:          params.get('PurposeNumber')
            };

            this.vimanForm.patchValue(formParams);
            console.log ('this.vimanForm:',this.vimanForm);
        });
    }
    close() {
        this.location.back();
    }

    getDonationTargetText(): string {
        let target = this.vimanForm.get('TargetType').value;
        if (target === 'DonationToProject') {
            return this.translate.instant('donation.donationToProject');

        } else if (target === 'DonationToBeneficiary') {
            return this.translate.instant('donation.donationToBeneficiary')
        } else {
            return this.translate.instant('donation.donationToPlatform')
        }
    }
    makePDF() {
        html2canvas(document.getElementById('virmanId')).then(canvas => {
            const pdf = new jsPDF('p', 'mm', 'a5');  // Initialize jsPDF with orientation ('p' or 'l'), unit ('mm', 'cm', 'in'), and page size ('a4', 'letter', etc.)

            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 297/2;  // width of A4 in mm
            const imgHeight = canvas.height * imgWidth / canvas.width;

            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save('Virman.pdf');
        });
    }
    makePDF_VECTOR() {
        const docDefinition = {
            content: [
                { text: 'This is a header', style: 'header' },
                'No styling here, this is a standard paragraph',
                { text: 'Another text', bold: true },
                // Include more text, styling, and other content as needed
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true
                }
                // Define more styles as needed
            }
        };

        pdfMake.createPdf(docDefinition).download('output.pdf');
    }

}
