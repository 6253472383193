<!-- Make Payment Component Template -->
<div class="payment-container">
    <p-confirmDialog header="Info" icon="pi pi-info-circle" [closable]="false"></p-confirmDialog>
    <p-confirmPopup key="confirmPopup"></p-confirmPopup>
    <p-confirmDialog header="Message" icon="pi pi-info-circle" [closable]="false"></p-confirmDialog>

    <div class="payment-content">
        <button type="button" class="close-btn" (click)="closeComponent()">
            <i class="fas fa-times"></i>
        </button>

        <div class="title-message">
            <div class="donationLabel" style="color:white; margin-top: 10px;">
                {{ inputForm.get('description').value }}</div>
            <div class="donationText" style="color:white;">{{ inputForm.get('title').value }} </div>
            <div class="donationText" style="color:yellow;"> Available: {{ sumAvailable | number }} MKD</div>
        </div>

        <div class="custom-message" style="border:1px solid lightgray; margin-top:0;">
            <div *ngIf="isVirmanDisabled">
                <div class="donationLabel">{{ 'donation.haveDonorProfile' | translate }}</div>
                <div class="donationText"> {{ 'donation.please' | translate }}
                    <a routerLink="/auth/login">{{ 'donation.login' | translate }}</a>
                    {{ 'donation.or' | translate }}
                    <a routerLink="/auth/register/donor/step1">{{ 'donation.register' | translate }}</a>
                    {{ 'donation.a-profile' | translate }}
                </div>
            </div>

            <form #donationForm [formGroup]="form">
                <div *ngIf="isVirmanDisabled">
                    <div style="border-top:1px solid lightgray; margin-top:10px;margin-bottom:20px;">
                        <div class="donationLabel">
                            {{ 'donation.donateWithoutProfile' | translate }}
                        </div>
                    </div>

                    <div class="p-inputgroup input-grp-donate">
                        <span class="p-inputgroup-addon input-frm-donate">
                            <i class="fas fa-user input-icon"></i>
                            <label for="firstName">{{ 'entity.person.personFirstName' | translate }}</label>
                        </span>
                        <input id="firstName" formControlName="firstName" pInputText type="text" class="input-txt-donate" autocomplete="off" />
                    </div>

                    <div class="p-inputgroup input-grp-donate">
                        <span class="p-inputgroup-addon input-frm-donate">
                            <i class="fas fa-user input-icon"></i>
                            <label for="lastName">{{ 'entity.person.personLastName' | translate }}</label>
                        </span>
                        <input id="lastName" formControlName="lastName" pInputText type="text" class="input-txt-donate" autocomplete="off" />
                    </div>

                    <div class="p-inputgroup input-grp-donate">
                        <span class="p-inputgroup-addon input-frm-donate">
                            <i class="fas fa-at input-icon"></i>
                            <label for="personEmail">{{ 'entity.person.personEmail' | translate }}</label>
                        </span>
                        <input id="personEmail"
                               formControlName="email"
                               pInputText type="text"
                               class="input-txt-donate"
                               autocomplete="off" />
                    </div>
                </div>

                <div style="border-top:1px solid lightgray; margin-top:10px; margin-bottom:20px;">
                    <div class="donationLabel">
                        {{ 'donation.donationAmount' | translate }}
                    </div>
                </div>

                <div class="button-container-donate">
                    <button class="money-button" [class.selected]="selectedAmount === 200" (click)="selectAmount(200)">200 MKD</button>

                    <button class="money-button" [class.selected]="selectedAmount === 500" (click)="selectAmount(500)">500 MKD</button>

                    <button class="money-button" [class.selected]="selectedAmount === 1000" (click)="selectAmount(1000)">1000 MKD</button>

                    <button class="money-button" [class.selected]="selectedAmount === 3000" (click)="selectAmount(3000)">3000 MKD</button>
                </div>

                <div class="input-wrapper">
                    <label class="fixed-width-label">
                        {{ 'donation.otherAmount' | translate }}
                    </label>

                    <div class="p-inputgroup opop-field-height-donate">
                        <p-inputNumber class="custom-inputNumber no-rounded-corners" formControlName="amount" (onFocus)="gotFocus()" [(ngModel)]="inputAmount"></p-inputNumber>
                        <span class="p-inputgroup-addon">MKD</span>
                    </div>
                </div>

                <div class="donationLabel" style="margin-left:10px; margin-right:10px; margin-top:20px; width:95%;" [ngStyle]="{'text-align': 'center'}">
                    <p>{{ 'donation.agreeTerms' | translate }}
                        <a class="cursor-pointer" (click)="showTerms()">
                            {{ 'donation.termsOfUse' | translate }}
                        </a>
                    </p>
                </div>

                <div class="button-container-donate">
                    <button class="money-button" style="width:100%; height:32px; margin-top:10px;margin-bottom:20px; font-weight:600" [class.selected]="true" [disabled]="isDonateButtonDisabled" (click)="selectButton('Card')">
                        <i class="fas fa-credit-card" style="margin-right:15px;"></i>
                        {{ 'pledge.cc' | translate }}
                    </button>
                </div>

                <div class="button-container-donate">
                    <button class="money-button"
                            style="width: 180px; height:44px;"
                            [class.selected]="!isVirmanDisabled"
                            (click)="selectButton('Wallet')"
                            [disabled]="inputAmount > sumAvailable || isVirmanDisabled"
                            [pTooltip]="('donation.walletHelp' | translate)" tooltipPosition="top"
                            [title]="isVirmanDisabled ? (('donation.login' | translate)+' '+('donation.or' | translate)+' '+('donation.register' | translate)+' '+('donation.aDonorProfileForWallet' | translate)) : (inputAmount > sumAvailable ? ('donation.notEnoughFunds' | translate) : '')">
                        <i class="fas fa-wallet" style="margin-right:5px;"></i>
                        {{ 'donor-dashboard.wallet' | translate }}
                    </button>

                    <button class="money-button" style="width:180px; height:44px;"
                            [class.selected]="!isVirmanDisabled"
                            (click)="makeVirman()"
                            [disabled]="isVirmanDisabled"
                            [pTooltip]="('donation.virmanHelp' | translate)"
                            [title]="isVirmanDisabled ? ('donation.login' | translate) + ' ' + ('donation.or' | translate) + ' ' + ('donation.register' | translate) + ' ' + ('donation.aDonorProfileForVirman' | translate) : ''">
                        <i class="fas fa-university" style="margin-right:5px;"></i>
                        {{ 'donation.bankTransfer' | translate }}&nbsp;&nbsp;&nbsp;&nbsp;
                    </button>

                    <button class="money-button" style="width: 180px; height:44px;"
                            [class.selected]="!isVirmanDisabled"
                            (click)="donorMakeTopUp()"
                            [pTooltip]="('donation.walletHelp' | translate)" tooltipPosition="top"
                            [title]="isVirmanDisabled ? (('donation.login' | translate)+' '+('donation.or' | translate)+' '+('donation.register' | translate)+' '+('donation.aDonorProfileForWallet' | translate)) : (inputAmount > sumAvailable ? ('donation.notEnoughFunds' | translate) : '')">
                        <i class="fas fa-wallet" style="margin-right:5px;"></i>
                        {{ 'donor-dashboard.topup' | translate }}
                    </button>
                </div>

                <small *ngIf="isVirmanDisabled">
                    <div class="text-center donationLabel" style="margin-top:15px;">
                        <a routerLink="/auth/login">
                            {{ 'donation.login' | translate }}
                        </a>
                        {{ 'donation.or' | translate }}
                        <a routerLink="/auth/register/donor/step1">
                            {{ 'donation.signup' | translate }}
                        </a>
                    </div>
                </small>
            </form>

            <form #formCasys method="post" action="https://www.cpay.com.mk/client/Page/default.aspx?xml_id=/en-US/.loginToPay/.simple/">
                <app-cpay [cpay]="cpay"></app-cpay>
            </form>
        </div>
    </div>
</div>
