import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { MenuItem, MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { TimerComponent } from "../timer.component";
import { errorHandler } from "../../../../support/functions";
import { BeneficiaryService } from "../../../../services/beneficiary.service";
import { Beneficiary } from "../../../../model/beneficiary";
import { beneficiarySteps } from "./beneficiary-steps";

@Component({
    templateUrl: './beneficiary-register-step3.component.html'
})
export class BeneficiaryRegisterStep3Component implements OnInit {
    isDesktop: boolean = true;
    public form: UntypedFormGroup;
    private readonly beneficiary: Beneficiary;
    disableEmailOtp = false;
    disableSmsOtp = false;
    disableSubmit = false;
    @ViewChild('timerEmailOtp') timerEmailOtp: TimerComponent;
    @ViewChild('timerSmsOtp') timerSmsOtp: TimerComponent;
    items: MenuItem[];

    constructor(public layoutService: LayoutService, private router: Router, private beneficiaryService: BeneficiaryService,
                private msgService: MessageService, private translate: TranslateService) {
        this.onResize();
        this.beneficiary = this.router.getCurrentNavigation().extras?.state?.beneficiary;
        this.items = beneficiarySteps(this.translate);
        this.form = new UntypedFormGroup({
            emailPin: new UntypedFormControl('1111'),
            phonePin: new UntypedFormControl('0000'),
            beneficiaryId: new UntypedFormControl(null)
        });
    }

    ngOnInit(): void {
        if (!this.beneficiary) {
            this.router.navigate(['/']);
            return;
        }
        this.form.controls.beneficiaryId.setValue(this.beneficiary.id);
        this.form.controls.phonePin.setValue('0000');
        this.form.controls.emailPin.setValue('1111');
        // this.submit();  // Automatically submit the form on initialization
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.isDesktop = window.innerWidth >= 1024;
    }

    get dark(): boolean {
        return this.layoutService.config.colorScheme !== 'light';
    }

    submit() {
        this.disableSubmit = false;

        // Clean up the values if necessary
        const emailPin = this.form.controls.emailPin.value.replaceAll(' ', '');
        const phonePin = this.form.controls.phonePin.value.replaceAll(' ', '');

        // Set cleaned values back to the form controls
        this.form.controls.emailPin.setValue(emailPin);
        this.form.controls.phonePin.setValue(phonePin);

        // Make sure beneficiaryId is set
        this.form.controls.beneficiaryId.setValue(this.beneficiary.id);

        // Get the updated form values
        const otpConfirmation = this.form.value;

        firstValueFrom(this.beneficiaryService.verifyOtp(otpConfirmation))
            .then(result => {
                    if (result && result.correct) {
                        this.router.navigateByUrl(`auth/register/beneficiary/step2`, { state: { beneficiary: this.beneficiary } });
                    } else {
                        errorHandler(this.translate.instant('authentication.otp.invalidOtpCode'), {}, this.msgService, this.translate, this.router);
                    }
                    this.disableSubmit = false;
                },
                error => {
                    this.msgService.add({
                        severity: 'error', life: 6000,
                        summary: 'Error',
                        detail: this.translate.instant(error.error?.message),
                    });
                    this.disableSubmit = false;
                }
            );
    }

    requestNewOtpSms() {
        if (!this.beneficiary) {
            return;
        }

        this.disableSmsOtp = true;

        firstValueFrom(this.beneficiaryService.regenerateOtpPhone(this.beneficiary.id))
            .then(() => {
                    this.disableSmsOtp = true;
                    this.msgService.add({
                        severity: 'info', life: 7000,
                        detail: this.translate.instant('authentication.otp.smsOtpCodeRequested')
                    });

                    this.timerSmsOtp.count();
                    setTimeout(() => this.disableSmsOtp = false, 30000);
                },
                error => {
                    this.disableSmsOtp = false;
                    errorHandler(this.translate.instant('authentication.otp.requestNewOtpSmsFailed'), error, this.msgService, this.translate, this.router);
                }
            );
    }

    requestNewOtpEmail() {
        if (!this.beneficiary) {
            return;
        }

        this.disableEmailOtp = true;

        firstValueFrom(this.beneficiaryService.regenerateOtpEmail(this.beneficiary.id))
            .then(() => {
                    this.disableEmailOtp = true;
                    this.msgService.add({
                        severity: 'info', life: 7000,
                        detail: this.translate.instant('authentication.otp.emailOtpCodeRequested')
                    });

                    this.timerEmailOtp.count();
                    setTimeout(() => this.disableEmailOtp = false, 30000);
                },
                error => {
                    this.disableEmailOtp = false;
                    errorHandler(this.translate.instant('authentication.otp.requestNewOtpEmailFailed'), error, this.msgService, this.translate, this.router);
                }
            );
    }
}
