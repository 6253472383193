import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {firstValueFrom} from "rxjs";
import {BeneficiaryService} from "../../../../services/beneficiary.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {MenuItem, MessageService} from "primeng/api";
import {beneficiarySteps} from "./beneficiary-steps";
import {Beneficiary} from "../../../../model/beneficiary";
import {BankService} from "../../../../services/bank.service";

@Component({
    templateUrl: './beneficiary-register-step2.component.html'
})
export class BeneficiaryRegisterStep2Component {
    public form: UntypedFormGroup;
    items: MenuItem[];
    private readonly beneficiary: Beneficiary;

    constructor(private beneficiaryService: BeneficiaryService, private router: Router, private translate: TranslateService,
                private msgService: MessageService, private bankService: BankService) {
        this.items = beneficiarySteps(this.translate);
        this.beneficiary = this.router.getCurrentNavigation().extras?.state?.beneficiary;
        this.form = new UntypedFormGroup({
            accountName: new UntypedFormControl(null, Validators.required),
            bankName: new UntypedFormControl(null, Validators.required),
            address: new UntypedFormControl(null),
            zip: new UntypedFormControl(null),
            accountNumber: new UntypedFormControl(null, Validators.required),
            iban: new UntypedFormControl(null),
            swift: new UntypedFormControl(null),
            inactive: new UntypedFormControl(false)
        });
    }

    submit() {
        this.beneficiary.company.bank = this.form.getRawValue();
        this.router.navigateByUrl(`auth/register/beneficiary/step4`, {state: {beneficiary: this.beneficiary}})

    }
}
